@font-face {
  font-family: 'Noto Sans CJK TC';
  src: url('http://18.167.151.93:30007/themes/custom/astrazeneca/fonts/NotoSansCJKtc-Black.otf');
}

.ant-modal-body {
  padding: 0px !important;
}

.ant-modal-confirm-info>.ant-modal-content>.ant-modal-body {
  padding: 24px !important;
}

.ant-modal-confirm>.ant-modal-content>.ant-modal-body {
  padding: 24px !important;
}

html {
  overflow: overlay !important;
}

body {
  overflow: overlay;
  width: unset !important;
}

.ant-radio-wrapper {
  width: 80px;
  display: inline-block;
}

.ant-modal-wrap {
  overflow: overlay;
}

.aaaza-buttom-1 {
  background: url(https://z3.ax1x.com/2021/04/15/c2Spu9.png);
}

.aaaza-buttom-1:hover {
  background: url(https://z3.ax1x.com/2021/04/15/cgzxc4.png);
}

.aaaza-buttom-2 {
  background: url(https://z3.ax1x.com/2021/04/15/c2SfV1.png);
}

.aaaza-buttom-2:hover {
  background: url(https://z3.ax1x.com/2021/04/15/c2S456.png);
}

.aaaza-buttom-3 {
  background: url(https://z3.ax1x.com/2021/04/15/c2pcJf.png);
}

.aaaza-buttom-3:hover {
  background: url(https://z3.ax1x.com/2021/04/15/c2pWQg.png);
}

.ant-form-item-has-error .ant-input {
  border-color: #794dff !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: #794dff !important;
}

.Booking .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border-color: #794dff !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ffe6f2 !important;
}

.ant-modal.bookingModal {
  box-sizing: border-box;
  overflow: hidden;
  border: 9px solid #fdeef3;
  padding-bottom: 0px;
  border-radius: 50px;
  background-color: white;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector,
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector {
  box-shadow: 0 0 0 2px rgb(215 77 255 / 20%) !important;
}

.bookingResultModal.ant-modal {
  background: #ff000000;
}

.bookingResultModal.ant-modal .ant-modal-content {
  margin-top: 110px;
}

.grecaptcha-badge {
  display: none !important;
}

/* .ant-row.ant-form-item{
   margin: 0 0 22px !important;
} */

::-webkit-scrollbar-track-piece {
  background-color: #f8f8f8;
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-thumb {
  background-color: #ddd;
  background-clip: padding-box;
  min-height: 28px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

.grecaptcha-badge {
  visibility: collapse !important;
}

.ant-menu::-webkit-scrollbar-thumb {
  background-color: #505050;
}

.ant-menu::-webkit-scrollbar-track-piece {
  background-color: #000c17;
}

.ant-menu::-webkit-scrollbar {
  width: 3px;
}

.title-css-experiment {
  /* font-family: 'Noto Serif TC', serif; */
  margin-bottom: 32px !important;
}

.title-css-experiment>span {
  border-bottom: 1px red solid;
}

.forxiga-pop {
  padding: 50px 50px 20px;
}

.reference a {
  word-break: break-all;
}

@media (max-width: 576px) {
  .home-banner .pc-btn .banner-img-btn {
    cursor: pointer;
    position: static;
    height: auto;
    width: 165px;
    top: 20%;
    right: 10px;
    display: block;
  }

  .forxiga-pop {
    padding: 50px 10px 20px;
  }
}

.dplayer {
  height: 100%;
}

.home-banner {
  width: 100%;
  position: relative;
}

.home-banner img {
  width: 100%;
  margin: 0;
  padding: 0;
}

.home-banner .mobile-btn {
  display: inline-block;
  color: #dd4d47;
  position: absolute;
  right: 12px;
  bottom: 3px;
  font-size: 16px;
  font-weight: 600;
}

.home-banner .pc-btn {
  position: absolute;
  right: 0px;
  left: 0;
  bottom: 0;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.red-text {
  display: inline-block;
  color: #dd4d47;
}

.pc-title {
  font-family: "Noto Sans CJK TC";
  color: #2E3192;
}

.pc-desc {
  font-family: "Noto Sans CJK TC";
  color: #2E3192;
}

.breadcrumbs {
  display: none;
}

.container {
  max-width: 100%;
}

.pc-btn .container {
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 100%;
}

@media (max-width: 560px) {
  .container {
    width: 100%;
    max-width: 500px;
  }

  .pc-title {
    font-size: 16px;
    font-weight: 600;
  }

  .pc-desc {
    font-size: 13px;
    line-height: 24px;
    margin-top: 4px;
  }

  .flash-but {
    font-size: 13px;
    line-height: 26px;
  }

  .flash-btn-text {
    line-height: 16px;
    font-size: 12px;
  }

  .home-banner img {
    display: none;
    /* width: auto;
    margin: 0;
    padding: 0;
    height: 200px;
    transform: translateX(-150px); */
  }

  .pc-btn .container {
    padding-left: 15px;
  }

  .home-banner {
    height: 300px;
  }

  .home-banner .pc-btn {
    height: 300px;
    background-image: url(https://login.az.yubis.net/sites/default/files/2022-04/forxiga_topbanner.png);
    /* left: 5%; */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

@media (min-width: 560px) and (max-width: 768px) {
  .flash-but {
    padding: 3px;
  }

  .flash-btn-text {
    line-height: 16px;
    font-size: 12px;
  }

  .home-banner .pc-btn {
    left: 5%;
  }

  .container {
    width: 100%;
    max-width: 650px;
  }

  .pc-title {
    font-size: 24px;
    font-weight: 700;
  }

  .pc-desc {
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    margin-top: 4px;
  }

  .home-banner .pc-btn .banner-img-btn {
    cursor: pointer;
    position: absolute;
    width: 26%;
    top: 11%;
    right: 26%;
    display: block;
  }

  .flash-but {
    font-size: 13px;
    line-height: 22px
  }

  .home-banner img {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .home-banner .pc-btn {
    left: 15%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .container {
    width: 750px;
  }

  .pc-title {
    font-size: 30px;
    font-weight: 600;
  }

  .pc-desc {
    font-size: 14px;
    line-height: 24px;
    margin-top: 6px;
  }

  .flash-but {
    font-size: 14px;
    line-height: 24px;
  }

  .flash-btn-text {
    font-size: 14px;
    line-height: 24px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .container {
    width: 970px;
  }

  .pc-title {
    font-size: 34px;
    font-weight: 700;
  }

  .pc-desc {
    font-size: 16px;
    line-height: 26px;
    margin-top: 8px;
  }

  .flash-but {
    font-size: 16px;
    line-height: 26px;
    padding: 5px 0;
  }

  .flash-btn-text {
    font-size: 16px;
    line-height: 26px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }

  .pc-title {
    font-size: 38px;
    font-weight: 900;
  }

  .pc-desc {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-top: 10px;
  }

  .flash-but {
    font-weight: 500;
    line-height: 30px;
    padding: 5px 0;
  }

  .flash-btn-text {
    font-size: 22px;
    line-height: 30px;
  }
}


.photo-iframe {
  width: 100%;
}

.photo-iframe-form,
.custom-iframe-modal .ant-modal-content,
.custom-iframe-modal .ant-modal-body {
  width: 100%;
  height: 100%;
}

.custom-iframe-modal .ant-modal-body {
  line-height: 0 !important;
}

.flash-but {
  cursor: pointer;
}

iframe {
  width: 100%;
}


.pc-btn .banner-img-btn {
  cursor: pointer;
  position: absolute;
  width: 25%;
  top: 12%;
  right: 25%;
  max-width: 360px;
  display: block;
}